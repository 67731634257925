import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import inventory from "../../Assets/Projects/inventory.png";
import editor from "../../Assets/Projects/codeEditor.png";
import news from "../../Assets/Projects/news.png";
import mv from "../../Assets/Projects/mv.png";
import textutils from "../../Assets/Projects/textutils.png";
import weather from "../../Assets/Projects/weather.png";
import coffee from "../../Assets/Projects/coffee.png";
import contact from "../../Assets/Projects/contact.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={coffee}
              isBlog={false}
              title="Coffee Shop"
              description="Coffee Shop in which you can cart your coffees and make bills digitaly.This project uses major concepts of React.js and it contain css that added dynamically."
              ghLink="https://github.com/umaralam9002/Coffee-Shop"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mv}
              isBlog={false}
              title="Model View Controller"
              description="Creating a MVC of Register and Logout in Asap.Net Core in Web Application.By Using The Api The MVC connect the form With our Database (MySql)."
              ghLink="https://github.com/umaralam9002/CrudAppusingASPCoreWebAPI"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={textutils}
              isBlog={false}
              title="Text-Utils React App"
              description="I Created Text-Utils React App in which your text can be manipulated and you can preview your text.You will know how many letters in it how much time is required to read and you can perform Many Functions."
              ghLink="https://github.com/umaralam9002/Text-Utilis"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={news}
              isBlog={false}
              title="Live News React App"
              description="In This Website You can Watch the News Headlines of All Countries. The Data can be Fetch by News Api and render in our Page . The Articles can be divided into pages you can move to another pages till all the Headlines will Over"
              ghLink="https://github.com/umaralam9002/News-App"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Application Programming Interface"
              description="Api in Asap.Net Core Perform a Crud Operation In which we call The API by using Http request and connect the API with Our DataBase (MySql) . "
              ghLink="https://github.com/umaralam9002"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={contact}
              isBlog={false}
              title="Contact List with Firebase"
              description="In which you can save your Contacts in firebase .This project contain restrictions on inputs that show the alerts on top and save your data Clean in a Successfull way"
              ghLink="https://github.com/umaralam9002/Contact-Reactapp"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={inventory}
              isBlog={false}
              title="Inventory Management System"
              description="The Inventory Management System is a C# Windows application designed to facilitate the efficient management of customer orders and product categories. Admins can manage customer information, oversee and process orders, and update product and category details."
              ghLink="https://github.com/umaralam9002"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={weather}
              isBlog={false}
              title="Weather Website"
              description="I created a weather website using HTML, CSS, and JavaScript that fetches data from a weather API. Users can check the temperature and humidity for different locations, providing real-time weather updates in a user-friendly interface."
              ghLink="https://github.com/umaralam9002"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
